/* custom imports */

@use '@angular/material' as mat;
@use "@material/fab";
@include fab.core-styles;

@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/brands";

@import 'variables';
// @import "../../../../node_modules/font-awesome/scss/font-awesome";
// @import '@angular/material/theming';
@include mat.core();

$md-firstcolor:(
  0: #000000,
  10: #000091,
  20: #0001ac,
  25: #0001cd,
  30: #0000ef,
  35: #1a21ff,
  40: #343dff,
  50: #5a64ff,
  60: #7c84ff,
  70: #9da3ff,
  80: #bec2ff,
  90: #e0e0ff,
  95: #f1efff,
  98: #fbf8ff,
  99: #fffbff,
  100: #ffffff,
  secondary: (
    0: #000000,
    10: #191a2c,
    20: #2e2f42,
    25: #393a4d,
    30: #444559,
    35: #505165,
    40: #5c5d72,
    50: #75758b,
    60: #8f8fa6,
    70: #a9a9c1,
    80: #c5c4dd,
    90: #e1e0f9,
    95: #f1efff,
    98: #fbf8ff,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1b1b1f,
    20: #303034,
    25: #3c3b3f,
    30: #47464a,
    35: #535256,
    40: #5f5e62,
    50: #78767a,
    60: #929094,
    70: #adaaaf,
    80: #c8c5ca,
    90: #e5e1e6,
    95: #f3eff4,
    98: #fcf8fd,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral-variant: (
    0: #000000,
    10: #1b1b23,
    20: #303038,
    25: #3b3b43,
    30: #46464f,
    35: #52515b,
    40: #5e5d67,
    50: #777680,
    60: #91909a,
    70: #acaab4,
    80: #c7c5d0,
    90: #e4e1ec,
    95: #f2effa,
    98: #fbf8ff,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$md-secondcolor: (
  0: #000000,
  10: #400200,
  20: #670500,
  25: #7B0800,
  30: #910B00,
  35: #A70E00,
  40: #BD1200,
  50: #EA1F06,
  60: #FF553C,
  70: #FF8A76,
  80: #FFB4A7,
  90: #FFDAD4,
  95: #FFEDEA,
  98: #FFF8F6,
  99: #FFFBFF,
  100: #FFFFFF,
  secondary: (
    0: #000000,
    10: #3D0600,
    20: #630F00,
    25: #771400,
    30: #8B1A00,
    35: #A01F00,
    40: #B42907,
    50: #D74220,
    60: #FA5B37,
    70: #FF8B70,
    80: #FFB4A3,
    90: #FFDAD2,
    95: #FFEDE9,
    98: #FFF8F6,
    99: #FFFBFF,
    100: #FFFFFF,
  ),
  neutral: (
    0: #000000,
    10: #201A19,
    20: #362F2D,
    25: #413A38,
    30: #4D4543,
    35: #59504F,
    40: #655C5B,
    50: #7F7573,
    60: #998E8C,
    70: #B4A9A7,
    80: #D0C4C2,
    90: #EDE0DD,
    95: #FBEEEB,
    98: #FFF8F6,
    99: #FFFBFF,
    100: #FFFFFF,
  ),
  neutral-variant: (
    0: #000000,
    10: #251916,
    20: #3B2D2B,
    25: #473835,
    30: #534340,
    35: #5F4F4C,
    40: #6C5B57,
    50: #857370,
    60: #A08C89,
    70: #BCA7A3,
    80: #D8C2BE,
    90: #F5DDD9,
    95: #FFEDEA,
    98: #FFF8F6,
    99: #FFFBFF,
    100: #FFFFFF,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);


$my-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $md-firstcolor,
    tertiary: $md-secondcolor,
  ),
  density: (
    scale: 0,
  )
));

@mixin my-expansion-panel-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    // Define any styles affected by the theme.
    .mat-expansion-panel-content {
        font: inherit;
        color: inherit;

    }
    .mat-expansion-panel-header{

        &.mat-expanded{

                background: mat-color($accent, lighter, 10);
                color: mat-color($foreground, text);
        }
    }
  }


html {
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  @include mat.core-theme($my-theme);
  @include mat.expansion-theme($my-theme);
  @include mat.dialog-theme($my-theme);
}

.btn {
  padding: 1.2rem 1.66rem;
  &.btn-lg {
    font-size: 1.2rem;
  }
  &.btn-sm {
    font-size: 1rem;
  }
  &.btn-xs {
    font-size: 0.91rem;
  }
}

/* REM calculations */
/*
1rem - 12px
2rem - 24px

10px ~ 0.83rem
13px ~ 1.1rem
15px ~ 1.25rem
*/

.btn{
  color: #ffffff;
  &:hover{
    color: #ffffff;
  }
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
}

.btn-as-link {
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  display: inline;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.breadcrumb{
  padding: 0.75rem 1rem;
  @media screen and (max-width: 450px) {
    margin-bottom: 10px !important;
  }
}

.pointer{
  cursor: pointer;
}

.btn {
  &.btn-small{
    padding: 12px 20px;
  }
  &.btn-medium{
    padding:13px 20px
  }
  &.btn-smallest{
    padding: 11px 20px;
  }
}

body {
  overflow-x: hidden;
  font-size: 1.1rem;
}

// table > thead th{
//   white-space: nowrap;
// }

// table > tbody tr td{
//   white-space: nowrap;
// }

table.table-head-sticky{
  position: relative;
  tr {
    th{
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
   background-color: #fbfbfc;
 }

h1, h2, h3, h4, h5, h6 {
  color: theme-color('dark');
}

p{
  &.sub{
    font-size: 0.9rem
  }
}

h1{
  font-family: "Helvetica Light", "Helvetica Neue", Helvetica;
  font-weight: 500;
  color: theme-color('dark');
  font-size: 33px;
}
h2{
  font-family: "Helvetica Light", "Helvetica Neue", Helvetica;
  font-weight: 500;
  color: theme-color('dark');
  font-size: 26px;
}
h3{
  font-family: "Helvetica Light", "Helvetica Neue", Helvetica;
  font-weight: 500;
  color: theme-color('dark');
  font-size: 21px;
}
h4{
  font-family:"Helvetica Neue", Helvetica;
  font-weight: 700;
  color: theme-color('dark');
  font-size: 17px;
}
h5{
  font-family:"Helvetica Neue", Helvetica;
  font-weight: 700;
  color: theme-color('dark');
  font-size: 15px;
}
h6{
  font-family:"Helvetica Neue", Helvetica;
  font-weight: 700;
  color: theme-color('dark');
  font-size: 13px;
}
.cke_reset_all *{
  cursor: unset!important;
}

.tab-group {
  .mat-mdc-tab-header{
    border: 1px solid var(--lines);
    border-radius: 5px;
    background-color: white;
  }

  .mdc-tab--active {
    .mdc-tab__content{
      h5{
        color: var(--primary) !important;
      }
    }
    .mdc-tab-indicator{
      color: var(--primary) !important;
    }
  }

  &.strong {
    .mat-mdc-tab-header {
      .mdc-tab__content{
        font-weight: bold;
      }
    }
  }

}

.mat-mdc-dialog-surface{
  border-radius: 4px !important;
}

.mat-mdc-tab.mdc-tab{
  flex-grow: 0 !important;
}

.container{
  &.large{
    @media (min-width: 1700px){
      max-width: 1560px;
    }

    @media (width: 1440px) and (max-height: 990px){
      max-width: 1140px;
    }
  }
}

.sidebar-header{
  height: 5.3rem;
  background-color: var(--third);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5{
      margin-bottom: 0px;
      color: white;
  }
}

.sidebar-content{
  padding: 10px 10px 10px 20px;
  max-height: calc(100vh - 5.3rem);

  h5{
      margin-top: 10px;
  }
}

.ui-toast {

  .ui-toast-message-content {
    padding: 0.5em 0.5em!important;

  }
  .ui-toast-icon{
    border: 1px solid;
    left: 0.2em !important;
    border-radius: 50%;

  }
  .ui-toast-message-error{
    color:#934a51 !important;
    .ui-toast-icon{
      color:#934a51 !important;
      border-color: #934a51;
    }
  }
  .ui-toast-message-success{
    color:#1e603e !important;
    .ui-toast-icon{
      color:#1e603e !important;
      border-color: #1e603e;
    }
  }
  .ui-toast-message-info{
    color:#285f67 !important;
    .ui-toast-icon{
      color:#285f67 !important;
      border-color: #285f67;
    }
  }
  .ui-toast-message-warn{
    color:#be8a24 !important;
    .ui-toast-icon{
      color:#be8a24 !important;
      border-color: #be8a24;
    }
  }
}

.table {

  th, td {
    padding: 1.25rem 2rem;
  }
  &.table-custom-bordered {

    border-width:1px;
    border-style: solid;
    border-radius: 5px;
    border-collapse: separate !important;
    border-spacing: 0;
    th:first-child {
      border-radius: 5px 0 0 0;
    }
    th:last-child {
      border-radius: 0 5px 0 0;
    }

    thead th {
      border:5px;
      border-bottom: 0 !important;
      border-right-width: 1px;
      border-right-color: rgba($color: #000000, $alpha: 0.2);
      border-right-style: solid;
      &:last-child {
        border-right: 0;
      }
    }

    tr td {
      border-right-width: 1px;
      border-right-style: solid;
      // &:last-child {
      //   border-right: 0;
      // }
    }

    tbody tr:last-child {
      td:first-child {
        border-radius: 0 0 0 5px;
      }
      td:last-child {
        border-radius: 0 0 5px 0;
      }
    }
  }
}

.form-text {
  font-size: 1rem
}

.mat-slider-track-fill, .mat-slider-thumb, .mat-slider-thumb-label{
  background-color: var(--primary)!important;
}

.expansion-panel{
  &.overflow{
   .mat-content{
     overflow: visible!important;
   }
  }
  &.mat-expansion-panel{
    border-radius: 0;
  }
  &.mat-expansion-panel[data-first="true"]{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.mat-expansion-panel[data-last="true"]{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .mat-expansion-panel-header{
    background-color: white;
    // border-block-end: 1px custom-color('lines') solid;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    .mat-content{
      align-items: center;
    }
    &.mat-expanded{
      background-color: white;
      &:hover, &:focus{
        background: white;
      }
    }
  }
  &.mat-expansion-panel:not([class*=mat-elevation-z]){
    box-shadow: none!important;
    border: 1px custom-color('lines') solid;
  }
  .mat-expansion-panel-content{
    border-top: custom-color('lines') solid 1px;
    --mat-expansion-container-text-font: "Helvetica Neue", Helvetica;
    .mat-expansion-panel-body{
      padding: 2rem;
      background-color: #ffffff;
    }
  }
  &.blue{
    >.mat-expansion-panel-content{
      border-top-color: #89bde2!important;
      .mat-expansion-panel-body{
        padding: 0px!important;
        background-color: #97d3fa;
      }
    }
  }
  &.red{
    >.mat-expansion-panel-content{
      border-top-color: #b26e6f!important;
      .mat-expansion-panel-body{
        padding: 0px!important;
        background-color: #fe9fa0;
      }
    }
  }
  .mat-expansion-indicator{
    margin-bottom: 0.3rem;
    order: -1;
    margin-right: 1rem;
    transition: margin-bottom 0.5s
  }
  &.right-arrow{
    .mat-expansion-indicator{
      order: 0;
      margin-right: 0rem;
    }
  }
  &.mat-expanded{
    .mat-expansion-indicator{
      // margin-bottom: -0.8rem;
      transition: margin-bottom 0.5s
    }
  }
  &.no-border .mat-expansion-panel-content {
    border-top: 0px;
  }
  &.no-padding {
    margin: 0 !important;
  }
}

ul.dashed {
  margin: 0;
  padding-left: 5px;
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "-";
  text-indent: -5px;
  margin: 0px 5px;
}

label {
  margin-bottom: 0px;
}

.mat-expansion-panel-no-padding{
  .mat-expansion-panel-body{
    padding: 0px!important;
  }
}

.mat-dialog-container{
  background-color: #ffffff;
  padding: 0px!important;
  .mm-box-container{
    margin: 0px;
  }
}

.mat-mdc-tooltip{
  text-overflow: clip!important;
}

/* forms */
input.form-control {
  // line-height: 3rem;
  &[data-label="false"]{
    padding-top: 1rem!important;
    padding-bottom: 1rem;
  }
  font-size: 1.1rem;
  height: 4rem;
  &:focus {
    box-shadow: none;
  }
}

textarea.form-control {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

/* ngprime */
.p-component {
  font-family: "Helvetica Neue", Helvetica;
  font-size: 1rem;
  font-weight: normal;
}

/* buttons */
button.btn {
  border-style: solid;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }

  &.btn-fab{
    border-radius: 50%;
    box-shadow: 1px 9px 78px -4px rgba(0,0,0,0.48);
    font-size: 2rem;
    padding: 1.5rem;
  }
}

.btn-check:focus + .btn-secondary{
  color:#ffffff;
}
.btn-primary,
.btn-secondary{
  &:focus{
    color:#ffffff;
  }
}

.p-sidebar{
  .p-sidebar-header,
  .p-sidebar-content{
    padding:0 !important;
    overflow-x: hidden;
  }
}

.p-sidebar-content {
  height: 100%;
}

.line-height{
  line-height: 0;
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.btn {
  font-size: 1.1rem;
}

/* input groups */
.input-group > app-search-suggest .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* -5-fieldset-haruki component */
mmp5-fieldset-haruki {
  .form-control {
    // line-height: 2.5rem;
    text-overflow: ellipsis;
    &:focus {
      box-shadow: none;
    }
  }
}


/* search suggest component */
mmp5-search-suggest {
  .container-suggestions {
    @include mm-shadow;
    border-width: 1px;
    border-style: solid;
  }
}

/* alert component */
#alertComponent-container {
  @include mm-parent-container;
  h1 {
    font-size: 1rem;
    @include mm-header-container;
  }
  > p, > div {
    @include mm-container;
  }

}

/* default box container */
.mm-box-container {
  @include mm-parent-container;
  .mm-box-header {
    @include mm-header-container;
  }
  .mm-box-content {
    @include mm-container;
  }
  .mm-box-footer {
    @include mm-footer-container;
  }
}


/* pagination */
.pagination {
  li.page-item {
    margin: 0 5px;
  }
}
.page-item {
  .page-link {
    border-radius: 5px;
  }
}

.button-flex-center{
  display: flex;
  justify-content: center;
}

/* datepicker */
ngb-datepicker {
  padding: 1.5rem;
  z-index: 2!important;

  .bg-light {
    background: transparent !important;
  }
  .ngb-dp-weekday.font-italic {
    font-style: normal !important;
  }
  .ngb-dp-weekday, .ngb-dp-day {
    width: 2.7rem !important;
    height: 2.5rem;
    outline: none;

    .btn-light{
      width: unset;
    }
  }

  .ngb-dp-weekday{
    color: var(--third);
    font-size: 13px;
    font-style: normal;
  }

  .bg-primary {
    border-radius: 2rem !important;
    font-weight: 700;
  }

  // .ngb-dp-today {
  //   div{
  //     background-color: var(--third);
  //     border: 1px solid transparent;
  //     border-radius: 50%;
  //     color:#ffffff !important;

  //   }
  // }

  &.dropdown {
    // .ngb-dp-weekday {
    //   color: inherit !important;
    //   display: inline-block;
    // }

    background-color: white !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: 5px !important;

    .ngb-dp-header {
      position: relative;
      background-color: white;

      &:before, &:after {
        content: '';
        display: inline-block;
        position: absolute;
      }
      &:before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom-width: 10px;
        border-bottom-style: solid;
        top: calc(-2rem - 4px);
        left: -10px;
      }
      &:after {
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom-width: 9px;
        border-bottom-style: solid;
        top: calc(-2rem - 2px);
        left: -9px;
      }
    }

    &.right{
      right: 0px;

      .ngb-dp-header{

        &:before {
          left: unset;
          right: -10px;
        }

        &:after {
          left: unset;
          right: -9px;
        }
      }
    }
    .ngb-dp-week.ngb-dp-weekdays.bg-light{
      display: none;
    }

    ngb-datepicker-month{
      // padding-top: 30px;
      .ngb-dp-weekdays{
        background-color: white;
        border-bottom: 0;
      }
    }

    .ngb-dp-arrow{
      display: none!important;

    }

    .ngb-dp-navigation-select{
      margin-right:9rem;

    }
  }

  &.calendar{
    width: 100%;
    padding: 1rem;

    border: none;
    // border-style: none;

    button.btn{
      border-style: unset;
      color: var(--text);
      &:hover{
        background-color: transparent;
        color: var(--link);
      }
      &:focus{
        outline: none;
        box-shadow: unset;
      }
    }

    .ngb-dp-header{
      margin: 0 0 1.5rem 0;
      // display: none;
    }

    .ngb-dp-month-name{
      font-weight: 600;
    }

    .ngb-dp-month{
      width: 100%;
    }

    .ngb-dp-week{
      display: flex;
      justify-content: space-between;
    }

     .ngb-dp-weekdays {
         border-bottom: 0;
         border-radius: 0;
         background-color: white;
          text-transform: uppercase;
     }
     .ngb-dp-week-number {
        line-height: unset;
        text-align: unset;
        font-style: normal;
        font-weight: 700;
        color: var(--secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        padding-right: 4px;
     }
     [ngbDatepickerDayView]{
         height: 2.5rem;
         display: flex;
         justify-content: center;
         align-items: center;
         color: var(--text)
      }
      .text-mute{
        color: #8f94ad;
      }

      .has-task{
        background-color:#f2f2f2;
        color: var(--primary);
        border-top: 2px solid var(--primary);
        font-weight: bold;
      }

      .bg-primary{
        color: white;
      }

     .ngb-dp-navigation-select{
         margin-right: unset;
     }
  }

}
select.form-select {
  background-color: transparent;
  border: 0px;
  width: auto;
  background-position: right -0.25rem center;
}

.alert{
  padding: 0.75rem 1.25rem;
}

.btn-light.text-muted.outside {
  color: #ccd1e0
}

 .input-group-append .btn, .input-group-prepend .btn{
  z-index: 1;
}

/* ng select */
ng-select {
  &.form-control {
    line-height: 2rem;
    padding-left: 20px;
    padding-top: 1.5rem;
    font-size: 1rem;

    // .ng-select .ng-arrow-wrapper .ng-arrow {
    //   border-color: #999 transparent transparent;
    //   border-style: solid;
    //   border-width: 5px 5px 2.5px;
    // }

    .ng-dropdown-panel {
      padding: 0;
      border: 0;
      width: 100%;
      z-index: 1029;
      background-color: transparent;

      &.bottom {
        margin-top: 5px;
      }
    }

    .ng-dropdown-panel-items {
      background-color: white;
      border-width: 1px;
      border-style: solid;
      margin: 12px 21px 0px -22px;
      @include mm-shadow;
    }

    .ng-option {
      padding: 0.5rem 1rem;
    }

  }
  &[data-label="false"]{
    .ng-arrow-wrapper{
      top: 2px;
    };
    .ng-clear-wrapper{
      top: 0px;
      left: 8px;
  };
}
};

.ng-select .ng-arrow-wrapper {
	width: 25px;
    padding-right: 5px;
    top: -2px;
    padding-left: 5px;
    padding-right: 0
}

.ng-select .ng-clear-wrapper {
    top: -4px;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
	border-top-color: #666
}

.ng-select .ng-arrow-wrapper .ng-arrow {
	border-color: #999 transparent transparent;
	border-style: solid;
    border-width: 5px 5px 2.5px;
    margin-bottom: 4px;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: -2px;
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
}

.link-unstyled, .link-unstyled:link, .link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}


/* file drop */
.fieldset--haruki-filearea-filedrop {
  height: auto;
  border: none;
  border-radius: 0;
  .content {
    display: block !important;
    height: auto !important;
  }
}

/* collapse */
.collapsable {
  border-width:1px;
  border-style: solid;
  border-radius: 5px;
  margin: 0 0 1rem 0;

  .collapsable-header {
    border-bottom-width:1px;
    border-bottom-style: solid;

    button.collapsable-btn-toggle {
      margin-left: -15px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin-right: 2rem;
    }

    span {
      display: inline-block;
      margin-right: 1rem;
      padding: 0.5rem 2rem;
      color: white;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .collapsable-content {
    padding: 2rem;
  }

  &.hidden {
    .collapsable-content {
      display: none;
    }
    .collapsable-header {
      border-bottom: 0;
      button.collapsable-btn-toggle {
        border-radius: 0.25rem;
      }
    }
  }
}

.mm-box-header{
  h5{
    margin-bottom: 0;
  }
}

// alerts
.alert-darker{
  border: 1px solid #D5DCE8;
  border-radius: 5px;
  background-color: #DBE2EC;
  padding: 10px;
}

// tooltip
.cdk-overlay-container {
  z-index: 1000 !important;
}

.medal-sidebar{
  z-index: 1000 !important;
}
.p-component-overlay {
  z-index: 999 !important;
}

.sidebar-widgets-config {
  .cropperPosterBanner {
    .crop-modal{
      // .crop-modal-backdrop{
      //     top: 34% !important;
      // }
      footer {
        padding: 0 10px;
      }
    }
  }
}

// @import '@angular/material/theming';

// $mm-app-primary: mat-palette($mat-orange, A700);
// $mm-app-accent: mat-palette($mat-orange, A200, A100 , A400);
// $mm-app-theme: mat-light-theme($mm-app-primary, $mm-app-accent);

// @include angular-material-theme($mm-app-theme);

// .mat-checkbox-background {
//     border: 2px var(--primary) solid;
// }

.empty_alert{
  border: 1px solid custom-color('lines');
}

.mat-mdc-radio-button.mat-primary.mat-mdc-radio-checked .mdc-radio__outer-circle{
  border-color: var(--primary) !important;
}

.mat-mdc-radio-button.mat-primary .mdc-radio__inner-circle{
  border-color: var(--primary) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked.mat-secondary .mdc-radio__outer-circle{
  border-color: var(--secondary) !important;
}

.mat-mdc-radio-button.mat-secondary .mdc-radio__inner-circle{
  background-color: var(--secondary) !important;
}

.mat-mdc-checkbox{
  .mdc-form-field{
    margin: 3px 0;
  }
    --mdc-checkbox-state-layer-size: 18px;
}

.mat-mdc-checkbox-checked.mat-primary .mdc-checkbox__background{
    background-color: transparent!important;
}

.mat-mdc-checkbox-checked {
    &.mat-primary {
      .mdc-checkbox__background{
          color: var(--primary)!important;
      }

      .mat-checkbox-checkmark-path {
        stroke: var(--primary)!important;
      }
    }
    &.mat-secondary {
      .mdc-checkbox__background{
          color: var(--secondary)!important;
      }
      .mat-checkbox-checkmark-path {
        stroke: var(--secondary)!important;
      }
    }
    &.mat-third {
      .mdc-checkbox__background{
          color: var(--third)!important;
      }
      .mat-checkbox-checkmark-path {
        stroke: var(--third)!important;
      }
    }
    &.mat-fourth {
      .mdc-checkbox__background{
          color: var(--fourth)!important;
      }
      .mat-checkbox-checkmark-path {
        stroke: var(--fourth)!important;
      }
    }
    &.mat-fifth {
      .mdc-checkbox__background{
          color: var(--fifth)!important;
      }
      .mat-checkbox-checkmark-path {
        stroke: var(--fifth)!important;
      }
    }
    &.mat-sixth {
      .mdc-checkbox__background{
          color: var(--sixth)!important;
      }
      .mat-checkbox-checkmark-path {
        stroke: var(--sixth)!important;
      }
    }
}

.mat-mdc-checkbox-checked.mat-mdc-checkbox-disabled {
  .mdc-checkbox__background{
      color: var(--light-primary)!important;
  }
  .mat-checkbox-checkmark-path {
    stroke: var(--light-primary)!important;
  }
}
.mdc-checkbox__background {
    background-color: transparent;
    border-width: 1px!important;
    border-radius: 1px!important;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar{
  background-color: var(--primary);
}

a:hover{
  text-decoration: none;
}

// ng-prime
.ui-widget-overlay.ui-sidebar-mask{
  z-index: 106!important;
}

//utils classes

.nowarp {
  white-space: nowrap;
}

.uppercase{
  text-transform: uppercase;
}

.white-wrap{
  white-space: normal;
}

.d-contents{
  display: contents;
}

.tooltip-default{
  background-color: #868ba5;
  color:white !important;
  padding:13px !important;
  font-size:12px;
  border-radius: 4px;
}

.mmp-page-menu-margin{
  margin-top: calc(1.3rem + var(--page-menu-size));
}

.color-white{
  color:white;
}

.div-container-fluid{
  max-width: 100%;
  height: auto!important;
}

/* responsive scss */
/*
Breakpoints:
md: 768
sm: 576
*/

.break-all{
  word-break: break-all!important;
}

.break-word{
  word-break: break-word!important;
}

.overflow-hidden{
  overflow: hidden!important;
}

.overflow{
  overflow: visible!important;
}

@media (min-width: 770px) and ( max-width: 832px ) {
  .container{
    padding-left: 6rem;
  }
}

@media (min-width: 1000px) and (max-width: 1070px){
  .container{
    padding-left: 6rem;
  }
}

ul,ol{
  padding-left: 20px;
}

.img-fluid{
  max-width: 100%;
  height: auto;
}

.grid-card{
  .access-profiles__groups{
    display: flex;
    flex-wrap: wrap;
    .mm-box-content{
      p{
        flex-grow:1;
        font-size:13px!important;
      }
    }
}

.pin{
    margin-left: 20px;
}


.bold{
  font-weight: 700!important;
}

.xl {
  font-size: 20rem!important;
}

.sm {
  font-size: 1rem;
}

.md {
  font-size: 2rem;
}

.font-italic{
  font-style: italic;
}

.visible {
  overflow: visible;
}

.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-size-tooltip{
  font-size: 1.4rem;
}



@media(max-width: 2560px){
  .card{
      width: 24.25%;
      margin: 0 0.5% 0 0.5%;
      display: flex;
      .mm-box-container{
          display: flex;
          flex-direction: column;
          flex-grow: 2;
          .mm-box-content{
              padding: 0.8rem 2rem 1.8rem 2rem;
              display: flex;
              flex-direction: column;
              flex-grow: 2;
              justify-content: space-between;
              align-items: center;
              p{
                  font-size: 11px;
              }
          }
      }
  }
  section:nth-child(4n+1){
    width: 24.25%;
    margin: 0 0.5% 0 0;
  }
  section:nth-child(4n){
      width: 24.25%;
      margin: 0 0 0 0.5%;
  }
}


@media (max-width: 1200px){
    section:nth-child(3n+1){
        width: 32%;
        margin: 0 0.6% 0 0;
    }
    section:nth-child(3n){
        width: 32%;
        margin: 0 0 0 0.6%;
    }
    .card{
        width: 32%;
    }
}

@media (max-width: 999px){
    section:nth-child(4n+1){
        width: 49%;
        margin: 0 0.6% 0 0;
    }
    section:nth-child(4n){
        width: 49%;
        margin: 0 0 0 0.6%;
    }
    section:nth-child(3n){
        width: 49%;
        margin: 0 0 0 0.6%;
    }
    .card{
        width: 49%;
    }
}

@media (max-width: 425px){
    section:nth-child(4n+1){
        width: 100%;
        margin: 0;
    }
    section:nth-child(4n){
        width: 100%;
        margin: 0;
    }
    section:nth-child(3n){
        width: 100%;
        margin: 0;
    }
    .card{
        width: 100%;
    }
}
}

@media (max-width: 768px) {
  .feature-item,
  .form-page-content .form-list,
  .mm-box-container .mm-box-content {
    padding: 10px 10px !important;
  }

}

@each $color, $value in $theme-colors {
  .text-#{$color}{
    color: var(--#{$color})!important;
  }
}


  @import "../responsive/_max_medium";
