@mixin maximizedContainer($margin-top: 40px, $columns: 160px 1fr, $rows: calc(100vh - 75px), $conversation-font-size: 1rem ){
  margin-top: $margin-top;
  display: grid;
  grid-template-columns: $columns;
  grid-template-rows: $rows;
  border: 1px solid transparent;
  border-radius: 20px;

  .conversations {
    .title h3 {
      font-size: 1rem;
    }
    .search .label {
      font-size: 0.6rem;
    }
    .conversation-list {
      height: calc(100% - 235px) !important;
      .conversation{
        font-size: $conversation-font-size !important;
      }
    }
  }
}

@mixin aliceChatBot($right:80px){
  right: $right !important;
}

html,
body {
  font-size: 13px;
}

form.form-open {
  width: 100%;
  box-sizing: border-box;
  margin-left: 0;
}

.mt-1-m {
  text-align: right;
}

@media screen and (max-width: 450px) {
  .btn,
  .mm-box-header,
  .mm-box-header,
  .config-form,
  .config-header,
  .mm-box-footer,
  .table th,
  .table td {
    padding: 1rem !important;
  }
  .td_value {
    line-height: 1rem !important;
  }
  .mt-1-m {
    text-align: left;
    margin-top: 10px;
  }

  .remove-d-flex-mobile {
    display: block !important;
  }

  thead th {
    vertical-align: middle;
  }

  .container.large{
    &.alice{
      max-width: 100% !important;
    }
  }

  .maximized{
    &.open{
      .container.large{
        &.alice{
          @include maximizedContainer($conversation-font-size: 0.8rem);
          .chat-box{
            .send-message{
              grid-template-columns: 1fr 30px !important;
              .btn{
                padding: 0.5rem !important;
                width: 30px;
                align-self: center;
                justify-self: flex-end;
              }
              .label{
                font-size: x-small !important;
                left:14px !important;
              }
            }
          }
        }
      }
    }
  }

  .chat{
    &.alice-chatbot{
      @include aliceChatBot
    }
    .title{
      h2{
        font-size: 1rem !important;
      }
    }
    .answer{
      grid-template-columns: 40px 67% 70px !important;
      .avatar {
        img {
          width: 25px !important;
        }
      }
      .answer-bubble {
        padding: 10px !important;
      }
    }
    .prompt{
      grid-template-columns: 70px 1fr 40px !important;
      .avatar {
        img {
          width: 25px !important;
        }
      }
      .prompt-bubble {
        padding: 10px !important;
      }
    }
  }

}

@media screen and (max-width: 770px) and (min-width:450px) {
  .max-min-btn{
    font-size: 0.9rem;
  }
  .max-min-btn-2{
    font-size: 0.8rem;
  }

  .maximized{
    &.open{
      .container.large{
        &.alice{
          @include maximizedContainer
        }
      }
    }
  }
  .chat{
    &.alice-chatbot{
      @include aliceChatBot
    }
  }
}

@media screen and (max-width: 1460px) and (min-width: 1200px) {
  .mmp-nav-margin .container,
  .mmp-nav-margin.container,
  .navbar .container,
  .container.large {
    max-width: 1140px;
  }

}

@media screen and (max-width: 1460px) and (min-width: 1000px) {
  .max-min-btn{
    font-size: 0.9rem;
  }
  .max-min-btn-2{
    font-size: 0.8rem;
  }
}

::ng-deep {
  @media screen and (max-width: 450px) {
    .alert-info {
      margin: 10px 0px !important;
    }
  }
}
