@function custom-color($key) {
  @if map-has-key($custom-colors, $key) {
    @return map-get($custom-colors, $key);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

@function theme-color($key) {
  @if map-has-key($theme-colors, $key) {
    @return map-get($theme-colors, $key);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

$yiq-text-dark: black !default;
$yiq-text-light: white !default;
$yiq-contrasted-threshold: 150 !default;

@function color-yiq($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  // $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;
  $yiq:calc((($r * 299) + ($g * 587) + ($b * 114)) / 1000);

  @if ($yiq >= $yiq-contrasted-threshold) {
    @return $yiq-text-dark;
  } @else {
    @return $yiq-text-light;
  }
}

@import "../assets/sass/mixins_colors";

@mixin mm-parent-container {
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 2rem;
  @include color-mm-parent-container;
}

@mixin mm-container {
  padding: 2.5rem;
  @include color-mm-container;
}

@mixin mm-header-container {
  padding: 1.5rem 2.5rem;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  margin: 0;
  font-size: 13px;
  font-weight: 700;
  color: map-get($theme-colors, 'dark');
  @include color-mm-header-container;
}

@mixin mm-footer-container {
  padding: 2.5rem 2.5rem;
  border-width: 1px;
  border-style: dashed;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  @include color-mm-footer-container;
}

@mixin crossBrowser($property, $val){
  -webkit-#{$property}: $val;
  -moz-#{$property}: $val;
  -ms-#{$property}: $val;
  #{$property}: $val;
}

@mixin mm-shadow {
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.25);
  box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.25);
}

@mixin fieldset_in_column {
  mmp5-fieldset-haruki {
    display: block;
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@mixin hide-search-input-x {
  /* clears the 'X' from Internet Explorer */
  input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

  /* clears the 'X' from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

}


//mat-check

.mat-checkbox-inner-container {
  display: inline-block;
  height: 16px;
  line-height: 0;
  margin: auto;
  margin-right: 8px;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 16px;
  flex-shrink: 0;
}

.mat-checkbox-input {
  bottom: 0;
  left: 50%;
}
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
  position: absolute;
  left: calc(50% - 9px);
  top: calc(50% - 9px);
  height: 18px;
  width: 18px;
  z-index: 1;
  pointer-events: none;
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-mdc-checkbox-touch-target, .mat-mdc-radio-touch-target {
  height: 18px !important;
  width: 18px !important;
}

.mat-checkbox-persistent-ripple, .mat-checkbox.mat-disabled .mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple {
  opacity: 0;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity,-webkit-transform 0s cubic-bezier(0,0,.2,1);
  transition: opacity,transform 0s cubic-bezier(0,0,.2,1);
  transition: opacity,transform 0s cubic-bezier(0,0,.2,1),-webkit-transform 0s cubic-bezier(0,0,.2,1);
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mat-checkbox-persistent-ripple {
  width: 100%;
  height: 100%;
  transform: none;
}

.mdc-checkbox__background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 2px;
  box-sizing: border-box;
  pointer-events: none;
}

.mat-mdc-checkbox-disabled{
  .mdc-checkbox__background {
    border-color: rgba(0, 0, 0, 0.1)!important;
  }
}

.mdc-checkbox__background {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  transition: background-color 90ms cubic-bezier(0,0,.2,.1),opacity 90ms cubic-bezier(0,0,.2,.1);
}
.mdc-checkbox__background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 2px;
  box-sizing: border-box;
  pointer-events: none;
}

.mdc-label {
  margin-left: 6px !important;
}

//checkbox
.mat-mdc-radio-group{
  .mat-mdc-radio-button {
      .mat-radio-label {
          white-space: unset;
      }
      margin:0!important;
  }
}

.mat-mdc-radio-button .mdc-radio{
  padding: 0 !important;
}
