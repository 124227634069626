@mixin color-mm-container {

}

@mixin color-mm-parent-container {
  background-color: white;
  border-color: custom-color('lines');
}

@mixin color-mm-header-container {
  border-bottom-color:custom-color('lines');
}

@mixin color-mm-footer-container {
  border-top-color: custom-color('lines');
}

@mixin color-mm-shadow {
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.25);
  box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.25);
}
