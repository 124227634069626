
/* header color */
app-view header {
  background: url('../assets/images/mmp5_opaque.png') 20px no-repeat theme-color('dark');
  color: white;
}

app-client-form mmp5-wizard-steps {
  margin-bottom: 20px;
}

/* recaptcha */
re-captcha {
  div {
    margin: 0 auto;
  }
}


body {
  &.yellow {
    background: linear-gradient(to right, rgba(255,146,38,0.2), rgba(255,146,38,0.8)),
                url('../assets/images/background/pattern.png'),
                url('../assets/images/background/call-center.jpg');
  }
  &.purple {
    background: linear-gradient(to right, rgba(162,145,216,0.2), rgba(162,145,216,0.8)),
                url('../assets/images/background/pattern.png'),
                url('../assets/images/background/call-center.jpg');
  }

  &.logged {
    padding-top: 90px;
  }
}


/* clients list */
app-client-list {
  .clients-list-widgets {

    > article > div {
      h2 {
        color: custom-color('text');
      }
      p {
        color: theme-color('dark');
        span {
          &.positive {
            color: theme-color('info');
          }
          &.negative {
            color: theme-color('danger');
          }
        }
      }
    }
  }
}

form-default label {
  text-transform: capitalize;
}

form.form-open {
  border-radius: 0 10px 0 0;
  width: 382px;
  box-sizing: border-box;
  margin-left: 100px;
  footer {
    margin: 20px 0;
  }
}

/* clients list */
app-client-list {
  .clients-list-parent {
    @include mm-parent-container;
    > section {
      @include mm-container;
    }
  }

  .clients-list-widgets {
    > article > div {
      @include mm-parent-container;
    }
  }
}

/* client detail */
app-client-detail {
  .client-detail-data {
    > section {
      @include mm-parent-container;
      > div {
        @include mm-container;
      }
    }
    h3 {
      @include mm-header-container;
    }
  }

  .client-dashboard-usage-header {
    @include mm-parent-container;
    h3 {
      @include mm-header-container;
    }
    > div {
      @include mm-container;
    }
  }

}

/* client form */
app-client-form {


  form {
    @include mm-parent-container;
    > div > div {
      @include mm-container;
    }
  }

  form h3 {
    @include mm-header-container;
  }


  form footer {
    @include mm-container;
    @include mm-footer-container;
  }

  @include fieldset_in_column;
}

/* begin password reset */
app-password-reset {
  form {
    > p:first-child {
      @include mm-header-container;
    }
  }
}

/* reset password */
app-validate-signup {
  form {
    > p:first-child {
      @include mm-header-container;
    }
  }
}
