$theme-colors: (
"primary": #ff5e3a,
"secondary": #38a9f6,
"fourth":#08ddc1,
"fifth":#ffdc1b,
"sixth": #7c5ac2,
"dark": #515365,
"danger": #ff6967,
"info": #08ddc1,
"warning": #ffdc1b,
"success": #6bbf4b,
"alltexts": #232327
);

$custom-colors: (
"inactive": #9a9fbf,
"background": #edf2f6,
"lines": #e6ecf5,
"disabled": #8f94ad,
"text": #888da8,
"link": #38a9f6,
"tablestripped": #fbfbfc,
"alltexts": #232327
);

$all-colors: (
    primary: #ff5e3a,
    secondary: #38a9f6,
    fourth:#08ddc1,
    fifth:#ffdc1b,
    sixth: #7c5ac2,
    dark: #515365,
    danger: #ff6967,
    info: #08ddc1,
    warning: #ffdc1b,
    success: #6bbf4b,
    inactive: #9a9fbf,
    background: #edf2f6,
    lines: #e6ecf5,
    disabled: #8f94ad,
    text: #888da8,
    link: #38a9f6,
    tablestripped: #fbfbfc,
);

:root {
    --primary: #ff5e3a;
    --secondary: #38a9f6;
    --third: #38a9f6;
    --fourth:#08ddc1;
    --fifth:#ffdc1b;
    --sixth: #7c5ac2;
    --dark: #515365;
    --danger: #ff6967;
    --info: #08ddc1;
    --warning: #ffdc1b;
    --success: #6bbf4b;
    --inactive: #9a9fbf;
    --background: #edf2f6;
    --lines: #e6ecf5;
    --disabled: #8f94ad;
    --text: #888da8;
    --link: #38a9f6;
    --tablestripped: #fbfbfc;

    @each $name, $color in $all-colors {
        --light-#{$name}: #{lighten($color, 10%)};
    }
};

$variables: (
    'primary' : var(--primary),
    'secondary' : var(--secondary),
    'third' : var(--third),
    'fourth' :var(--fourth),
    'fifth' :var(--fifth),
    'sixth' : var(--sixth),
    'dark' : var(--dark),
    'danger' : var(--danger),
    'info' : var(--info),
    'warning' : var(--warning),
    'success' : var(--success),
    'inactive' : var(--inactive),
    'background' : var(--background),
    'lines' : var(--lines),
    'disabled' : var(--disabled),
    'text' : var(--text),
    'link' : var(--link),
    'tablestripped' : var(--tablestripped)
    );
