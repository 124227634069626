$theme-colors: (
"primary": #ff5e3a,
"secondary": #38a9f6,
"dark": #232327,
"danger": #ff6967,
"info": #08ddc1,
"warning": #ffdc1b,
"alltexts": #232327
);

$custom-colors: (
"inactive": #9a9fbf,
"background": #edf2f6,
"lines": #e6ecf5,
"text": #888da8,
"link": #38a9f6,
"tablestripped": #fbfbfc,
"alltexts": #232327
);


@import "./assets/color-var.scss";

@import "../../mm-ui-app/src/_scss/mixins";
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

$spacer: 3rem;
// @import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../../node_modules/bootstrap/scss/bootstrap-reboot";
@import "../../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../../node_modules/bootstrap/scss/bootstrap-utilities.scss";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/button-group";
// @import "../../../node_modules/bootstrap/scss/input-group";
@import "../../../node_modules/bootstrap/scss/utilities";

@import "../../mm-ui-app/src/_scss/layout";
@import "../../mm-ui-app/src/assets/custom_color";


/* custom project */
@import "_scss/custom";

.mmp-nav-margin{
    margin-top: 7rem;
}

@media(max-width:990px){
    .mmp-nav-margin{
        margin-top:9rem;
    }
}

body {
    font-family: "Helvetica Neue", Helvetica!important;
    .yellow{
        height: 100%;
        width: 100%;
        position: absolute;
        // background: -webkit-gradient(linear, left top, right top, from(rgba(255, 146, 38, 0.2)), to(rgba(255, 146, 38, 0.8)));
        // background: linear-gradient(to right, rgba(255, 146, 38, 0.2), rgba(255, 146, 38, 0.8));
        background: url('/assets/images/yellow.png'), url('/assets/images/woman.jpg');
        background-size: cover;
    }
    .purple {
        height: 100%;
        width: 100%;
        position: absolute;
        // background: -webkit-gradient(linear, left top, right top, from(rgba(162, 145, 216, 0.2)), to(rgba(162, 145, 216, 0.8)));
        // background: linear-gradient(to right, rgba(162, 145, 216, 0.2), rgba(162, 145, 216, 0.8));
        background: url('./assets/images/blue.png'), url('/assets/images/man.jpg');
        background-size: cover;
    }
}

a {
    font-family:"Helvetica Neue", Helvetica!important;
    font-weight: 500;
}

h1{
    font-family:"Helvetica Neue", Helvetica!important;
    font-weight: 500!important;
    color: #232327;
    font-size: 40px;
}
h1{
    font-family:"Helvetica Neue", Helvetica;
    font-weight: 500;
    color: #232327;
    font-size: 40px;
}
h2{
    font-family:"Helvetica Neue", Helvetica;
    font-weight: 500;
    color: #232327;
    font-size: 32px;
}
h3{
    font-family:"Helvetica Neue", Helvetica;
    font-weight: 500;
    color: #232327;
    font-size: 28px;
}
h4{
    font-family:"Helvetica Neue", Helvetica;
    font-weight: 700;
    color: #232327;
    font-size: 22px;
}
h5{
    font-family:"Helvetica Neue", Helvetica;
    font-weight: 700;
    color: #232327;
    font-size: 16px;
}
h6{
    font-family:"Helvetica Neue", Helvetica;
    font-weight: 700;
    color: #232327;
    font-size: 14px;
}
p{
    font-family:"Helvetica Neue", Helvetica;
    font-weight: 500;
    color: #8f94ad;
    .subtitle {
        font-family:"Helvetica Neue", Helvetica;
        font-weight: 700;
        color: #8f94ad;
    }
}
